body {
  background-color: #141414;
  color: #FFF;
  margin: 0;

  .home {
    background-color: #141414;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
      padding: 3em 2em 0em 2em;
      text-align: center;
      flex: 1;
      h1 {
        font-size: 2.5em;
        color: #FFF;
      }
      .subtitle {
        color: #FFF;
        font-size: 2em;
      }
    }
    .examples {
      margin: 4em 0;
      text-align: center;
      button {
        border-radius: 50px;
        padding: 1.5em 3em;
      }
    }
    .mobile {
      color: #FFF;
      margin-top: 2em;
    }
  }
}



model-viewer {
  width: 100%;
  height: 55vh;
  --poster-color: #ffffff00;
}

@media screen and (min-width: 1024px) {
  .footer-home {
    margin-top: 5em;
  }
}