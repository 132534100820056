.card {
  height: 24vh;
  .buttonSyrah {
    border: none;
    color: #FFF !important;
    justify-content: left;
    .image {
      position: absolute;
      right: 1em;
    }
  }
}