@font-face {
	font-family: 'MrsEaves';
	  src: url("../fonts/MrEaves/MrEavesXLModOT-Reg.ttf");
}

body {
  background-color: #F9F8F2;
  color: #161615;
}

#giuseppe {
  --poster-color: transparent;
}

.presentation {
  padding: 1.5em 2em 0em 2em;
  text-align: center;
  font-family: 'MrsEaves';
  .logo-giu {
    max-width: 200px;
  }
  .subtitle {
    color: #161615;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -.022em;
    padding-top: 1em;
  }
}

.decouvrir {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 1em;
  a {
    color: #fff;
    background-color: #161615;
    border-radius: 50px;
    padding: 1em 3em;
    &:hover {
      background-color: #e84924;
    }
  }
}

.viewer {
  padding: 3em 4em;
  text-align: center;
  .image-model {
    border-radius: 10px;
  }
  .text-model {
    color: #161615;
    padding-top: 1em;
    text-decoration: underline;
  }
}

.mobile {
  margin: 1em 0;
  text-align: center;
  .logo-download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .logo {
      max-width: 12vw;
      margin: 1em 1em 1em 1em;
    }
  }
}

.footer {
  color: #6E6E73;
  background-color: #FFF;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
  padding: 3em 1em;
  a {
    color: #161615;
    font-weight: 600;
  }
}

#ar-button {
  font-size: 1rem;
    height: 2em;
    background-color: #161615;
    border: 1px solid #000000;
    border-radius: 50px;
    color: #000000;
    justify-content: center;
    width: 78%;
    margin: 0 6%;
    position: absolute;
    bottom: 0;
}
.card {
  box-shadow: none;
  height: 24vh;
  .button {
    background-color: #000000;
    border: none;
    border-radius: 50px;
    color: #161615;
    justify-content: center;
    .image {
      position: absolute;
      right: 1em;
    }
  }
}

@media screen and (min-width: 768px) {
  .presentation {
    padding: 2.5em 8em 0 8em;
  }
  .viewer {
    padding: 3em 8em;
    text-align: center;
  }
  .mobile {
    .logo-download {
      .logo {
        max-width: 6vw;
        margin: 1em 1em 1em 1em;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .viewer {
    padding: 2em 8em;
    text-align: center;
  }
  .mobile {
    .logo-download {
      .logo {
        max-width: 3vw;
        margin: 1em 1em 1em 1em;
      }
    }
  }
}